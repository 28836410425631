import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Комп'ютерний Сервіс
			</title>
			<meta name={"description"} content={"У Комп'ютерний Сервіс ми розуміємо, яку важливу роль відіграє ваш комп'ютер у вашому повсякденному житті."} />
			<meta property={"og:title"} content={"Наші послуги | Комп'ютерний Сервіс"} />
			<meta property={"og:description"} content={"У Комп'ютерний Сервіс ми розуміємо, яку важливу роль відіграє ваш комп'ютер у вашому повсякденному житті."} />
			<meta property={"og:image"} content={"https://risonda.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://risonda.live/img/317-3179993.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://risonda.live/img/317-3179993.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://risonda.live/img/317-3179993.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://risonda.live/img/317-3179993.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://risonda.live/img/317-3179993.png"} />
			<meta name={"msapplication-TileImage"} content={"https://risonda.live/img/317-3179993.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://risonda.live/img/4.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
					Наші послуги
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					У Комп'ютерний Сервіс ми розуміємо, яку важливу роль відіграє ваш комп'ютер у вашому повсякденному житті. Наші послуги призначені для вирішення широкого спектру проблем з комп'ютером з ефективністю та досвідом. Ось короткий огляд того, що ми пропонуємо:
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-8">
			<Text margin="0px 0px 35px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Категорії послуг
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Діагностичний аналіз
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Перед будь-яким ремонтом ми проводимо ретельну діагностику, щоб виявити першопричину несправності вашого ПК. Такий підхід гарантує цілеспрямовані та ефективні рішення.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Апаратний ремонт та модернізація
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Від заміни несправних компонентів до модернізації системи для підвищення продуктивності - наші послуги з ремонту обладнання охоплюють все.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Вирішення проблем із програмним забезпеченням
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Незалежно від того, чи це постійний вірус, збій програмного забезпечення або нестабільність системи, наша команда здатна вирішити різні проблеми, пов'язані з програмним забезпеченням.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Відновлення та резервне копіювання даних
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Втрата даних може стати справжнім кошмаром. Наші послуги з відновлення даних спрямовані на те, щоб повернути те, що ви вважали втраченим, і надати рішення для резервного копіювання даних у майбутньому.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Збірка ПК на замовлення
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Для тих, хто шукає комп'ютер, який відповідає конкретним потребам, ми пропонуємо послугу створення ПК на замовлення, що поєднує в собі продуктивність, естетику та вартість.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Плани регулярного обслуговування
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Підтримуйте свій комп'ютер в ідеальному стані за допомогою наших планів технічного обслуговування, розроблених для запобігання проблемам до їх виникнення.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});